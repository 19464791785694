import {HorizontalAlignment as HAlignment, HStack, Spacer, VStack} from "no-react-stack";
import {CFormInput, CFormLabel, CInputGroup, CInputGroupText, CRow} from "@coreui/react";
import {ErrorMessage} from "@hookform/error-message";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import jwtDecoder from 'jwt-decode'
import moment from 'moment'
import axios from "axios";

const ResetPassword = () => {
    const {register, handleSubmit, formState: {errors}, setError} = useForm()
    useEffect(() => {
        let params = window.location.search.substr(1).split('&')
        let token
        let url
        for (let i in params) {
            if (params[i].indexOf('url=') !== -1) {
                url = params[i].substr('url='.length)
            }
            if (params[i].indexOf('token=') !== -1) {
                token = params[i].substr('token='.length)
            }
        }
        if (!url) {
            setError('response', {message: '缺少 url 參數'})
            return
        }
        if (!token) {
            setError('response', {message: '缺少 token 參數'})
            return
        }
        try {
            const decoded = jwtDecoder(token)
            if (decoded.exp < moment().unix()) {
                window.location.href = "/?url=" + url
            }
        }catch (e){
            window.location.href = "/?url=" + url
        }
    }, [])

    const onSubmit = async (data) => {
        if (data.password !== data.passwordAgain) {
            setError('response', {message: '請輸入符合一致的密碼'})
            return
        }
        let params = window.location.search.substr(1).split('&')
        let token
        let url
        for (let i in params) {
            if (params[i].indexOf('url=') !== -1) {
                url = params[i].substr('url='.length)
            }
            if (params[i].indexOf('token=') !== -1) {
                token = params[i].substr('token='.length)
            }
        }
        if (!url) {
            setError('response', {message: '缺少 url 參數'})
            return
        }
        if (!token) {
            setError('response', {message: '缺少 token 參數'})
            return
        }
        let response = await axios.patch("/oauth/auth/update", data, {headers: {'Authorization': 'Bearer ' + token}})
            .then((response) => response)
            .catch((e) => {
                setError('response', {message: '更新失敗'})
                return undefined
            })
        if (!response) return
        alert('更新成功')
        window.location.href = decodeURIComponent(url)
    }

    return <VStack className='w-fill' alignment={HAlignment.CENTER}>
        <Spacer/>
        <form className='center' onSubmit={handleSubmit(onSubmit)}>
            <HStack className="mb-3">
                <CFormLabel className="col-form-label col-1 me-2">修改密碼</CFormLabel>
                <CFormInput placeholder="修改密碼" aria-label="Username" type="password"
                            {...register("password", {required: '必填'})}
                />
                <ErrorMessage errors={errors}
                              name='password'
                              render={({message}) =>
                                  <CFormLabel
                                      className='text-danger col-form-label col-1 text-end'>{message}</CFormLabel>}
                />
            </HStack>
            <HStack className="mb-3">
                <CFormLabel className="col-form-label col-1 me-2">確認密碼</CFormLabel>
                <CFormInput placeholder="確認密碼" aria-label="Username" type="password"
                            {...register("passwordAgain", {required: '必填'})}
                />
                <ErrorMessage errors={errors}
                              name='passwordAgain'
                              render={({message}) =>
                                  <CFormLabel
                                      className='text-danger col-form-label col-1 text-end'>{message}</CFormLabel>}
                />
            </HStack>
            <input hidden={true} {...register('response')}/>
            <ErrorMessage errors={errors}
                          name='response'
                          render={({message}) =>
                              <div className='text-danger'>{message}</div>}
            />
            <button type="submit" className="mt-2 py-1 text-white c-primary rounded-pill border-0 w-100">送出</button>
        </form>
        <Spacer/>
    </VStack>
}

export default ResetPassword
