import {HorizontalAlignment as HAlignment, HStack, Spacer, VStack} from "no-react-stack";
import {CImage} from "@coreui/react";
import logo from "./resources/logo.png";

const Error = (props) => {
    return <VStack className='w-fill' alignment={HAlignment.CENTER}>
        <Spacer/>
        <div style={{width: '80vw'}}>
            <VStack alignment={HAlignment.CENTER}>
                <CImage src={logo} width={'200px'}/>
            </VStack>
        </div>
        <Spacer/>
        <h4>{props.message}</h4>
        <Spacer/>
        <Spacer/>
    </VStack>
}

export default Error
