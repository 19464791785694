import React from 'react';
import ReactDOM from 'react-dom';
import Login from './Login';
import reportWebVitals from './reportWebVitals';
import {ViewportProvider} from "./components/viewport";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './resources/app.css'
import ResetPassword from "./ResetPassword";
import Error from "./Eorror";

ReactDOM.render(
    <React.StrictMode>
        <ViewportProvider>
            <BrowserRouter>
                <Routes>
                    <Route path='/resetpassword' element={<ResetPassword/>}/>
                    <Route path='/' element={<Login/>}/>
                    <Route path='*' element={<Error message="404"/>}/>
                </Routes>
            </BrowserRouter>
        </ViewportProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
