import {
    CImage,
    CInputGroup,
    CInputGroupText,
    CFormInput,
} from "@coreui/react";
import {useForm} from "react-hook-form";
import {VStack, HorizontalAlignment as HAlignment, Spacer, HStack} from 'no-react-stack'
import {ErrorMessage} from "@hookform/error-message";
import axios from "axios";

import {useViewport} from "./components/viewport";

import logo from './resources/logo.png';
import {useEffect} from "react";

function Login() {
    const {width, height} = useViewport();
    if (width > height) {
        return <VStack className='w-fill' alignment={HAlignment.CENTER}>
            <Spacer/>
            <HStack style={{width: '80vw'}}>
                <Spacer/>
                <VStack alignment={HAlignment.CENTER}>
                    <FormBlock/>
                </VStack>
                <Spacer/>
                <LogoBlock/>
                <Spacer/>
            </HStack>
            <Spacer/>
        </VStack>
    } else {
        return <VStack className='w-fill' alignment={HAlignment.CENTER}>
            <Spacer/>
            <Spacer/>
            <LogoBlock/>
            <Spacer/>
            <div style={{width: '80vw'}}>
                <FormBlock/>
            </div>
            <Spacer/>
            <Spacer/>
        </VStack>
    }
}

const FormBlock = () => {
    const {register, handleSubmit, formState: {errors}, setError} = useForm()
    useEffect(() => {
        let params = window.location.search.substr(1).split('&')
        let url
        for (let i in params) {
            if (params[i].indexOf('url=') !== -1) {
                url = params[i].substr('url='.length)
                break
            }
        }
        if (!url) {
            setError('response', {message: '缺少url參數'})
        }
    }, [])
    const onSubmit = async (data) => {
        let response = await axios.post("/oauth/login", data)
            .then((response) => response)
            .catch((e) => {
                setError('response', {message: '登入失敗'})
                return undefined
            })
        if (!response) return
        let params = window.location.search.substr(1).split('&')
        let url
        for (let i in params) {
            if (params[i].indexOf('url=') !== -1) {
                url = params[i].substr('url='.length)
                break
            }
        }
        if (!url) {
            setError('response', {message: '缺少url參數'})
            return
        }
        if (decodeURIComponent(url).includes('?')) {
            window.location.href = decodeURIComponent(url) + '&token=' + response.data
        } else {
            window.location.href = decodeURIComponent(url) + '?token=' + response.data
        }
    }
    return <form className="h-100" onSubmit={handleSubmit(onSubmit)}>
        <VStack className="h-100" alignment={HAlignment.CENTER}>
            <Spacer/>
            <CInputGroup className="mb-3 w-100">
                <CInputGroupText id="basic-addon1">帳戶</CInputGroupText>
                <CFormInput placeholder="帳戶" aria-label="Username" aria-describedby="basic-addon1"
                            {...register("username", {required: '必填'})}/>
                <ErrorMessage errors={errors}
                              name='username'
                              render={({message}) =>
                                  <CInputGroupText
                                      className='text-danger'>{message}</CInputGroupText>}
                />
            </CInputGroup>
            <CInputGroup className="mb-3 w-100">
                <CInputGroupText>密碼</CInputGroupText>
                <CFormInput placeholder="密碼" aria-label="Username" type="password"
                            {...register("password", {required: '必填'})}
                />
                <ErrorMessage errors={errors}
                              name='password'
                              render={({message}) =>
                                  <CInputGroupText
                                      className='text-danger'>{message}</CInputGroupText>}
                />
            </CInputGroup>
            <input hidden={true} {...register('response')}/>
            <ErrorMessage errors={errors}
                          name='response'
                          render={({message}) =>
                              <div className='text-danger'>{message}</div>}
            />
            <button type="submit" className="mt-2 py-1 text-white c-primary rounded-pill border-0 w-100">登入</button>
            <Spacer/>
        </VStack>
    </form>
}

const LogoBlock = () =>
    <HStack>
        <Spacer/>
        <VStack alignment={HAlignment.CENTER}>
            <CImage src={logo} width={'200px'}/>
            <h3 className='mt-4'>歡迎正旻科技</h3>
        </VStack>
        <Spacer/>
    </HStack>


export default Login;
